
import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import { distOrderCountHttp } from "~/api/order";
import { latestDealRecordHttp } from "~/api/distributor";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import { dailyOrderHttp } from "~/api/order";
import {
  weChatScanCodeloginApi,
  loginHttp,
  loginBySmCodeHttp,
  getUserInfoHttp,
} from "~/api/user";
import { queryVerifyCustomerHttp } from "~/api/cusSer";
import { addBlackHttp } from "~/api/black";
import { sendSmsCodeHttp } from "~/api/comm";
import {
  createInviteCodeRelate,
  gameListHttp,
  getNewsListHttp,
  dyVideoJumpManage,
  ipinfo,
  updateLastLogTime,
} from "~/api/comm";
import { getRaffle } from "~/api/user";
import { goodsListHttp, dealGoodsData } from "~/api/goods";

@Component({
  name: "substationIndex14",
  components: {
    logoSearch5: require("~/components/comm/logoSearch/index5.vue").default,
    djhHeaderNav9: require("~/components/comm/djhHeaderNav/index9.vue").default,
    djhSwiper9: require("~/components/comm/djhSwiper/index9.vue").default,
    djhSwiper15: require("~/components/comm/djhSwiper/index15.vue").default,
    djhSwiper14: require("~/components/comm/djhSwiper/index14.vue").default,
    djhAd27: require("~/components/comm/djhAd/index27.vue").default,
    djhAd21: require("~/components/comm/djhAd/index21.vue").default,
    djhAd28: require("~/components/comm/djhAd/index28.vue").default,
    djhAd24: require("~/components/comm/djhAd/index24.vue").default,
    floatMenu9: require("~/components/comm/floatMenu/index9.vue").default,
    floatLeft1: require("~/components/comm/floatLeft/index.vue").default,
    newImg2: require("~/components/comm/newImg/index2.vue").default,
    goodsList9: require("~/components/comm/goodsList/goodsList11.vue").default,
    auctionList: require("~/components/comm/goodsList/auctionList.vue").default,
    homeLogin5: require("~/components/comm/homeLogin/index5.vue").default,
    lottery: require("~/components/comm/lottery/lottery.vue").default,
    scrollAuto: require("~/components/comm/lottery/scrollAuto.vue").default,
  },
})
export default class substationIndex14 extends Vue {
  [x: string]: any;
  @Mutation private SET_TOKEN_DATA!: any;
  @State((state) => state.web.distributorsObject) distributorsObject!: any;
  @Mutation private SET_HOTGAMES_DATA!: any;
  @State((state) => state.web.hotGamesArray) hotGamesArray!: any;
  @Mutation private SET_GAMELIST_DATA!: any;
  @State((state) => state.web.gameListArray) gameListArray!: any;
  @State((state) => state.web.swiperlist) swiperlist!: any;

  public verifyCode: any = "";
  public phoneNum: any = "";
  public sendCodeText: string = "获取验证码";
  private openLottery: boolean = false;
  public host: any;
  public inviteCode: any;
  public qqRegular: any;
  public eventMsgTost: any;
  public getCookie: any;
  public people: number = 0;
  public code: string = "";
  public setCookie: any;
  public isLottery: boolean = false;
  public countdown: number = 0;
  public lotteryObj: any = {
    list: [],
    count: 0,
  };
  public bindPhoneShow: boolean = false;
  public openUrl: any;
  public errorToastFn: any;
  public eventNotifyTost: any;
  //获取cookie
  private backclor: any =
    "linear-gradient(rgb(254,182 , 66) 0%, rgba(255, 226, 137, 0) 100%)";
  private isShow: boolean = true;
  private isLogined: Boolean = false; // 是否已登录
  private actor: string = ""; // 头像
  private nickName: string = ""; // 昵称
  private gameList: Array<any> = []; // 游戏列表
  private gameType: number = 1; // 游戏列表
  private nowGameTotal: number = 0;
  private startVal: number = 0;
  private isFirstShowInClient: boolean = false;
  private isRunCountFloat: boolean = false; //控制首屏轮播右侧的旋转动画是否启动暂停的标识
  private isHaveRunSecondFloat: boolean = false; //热门游戏的旋转是否已经触发过的表示
  private middleHotGameNowChooseItem: any = 0; // 控制热门游戏当前选择的是端游还是手游还是全部游戏
  private staticNumberForSplit: any = 26; //
  private isShowDownloadDialog = true;
  private allDealCount: any = ""; // 存放总成交
  private yesterdayOrder: any = ""; // 存放昨日成交
  private yesterdayView: any = ""; // 存放昨日浏览量
  private newsList: any = []; //公告列表
  private douyinVideoList: any = []; //找回视频列表
  private commonQuestionList: any = []; //常见问题列表
  private carouselList: any = []; //轮播列表
  private isShowFloatDownload: boolean = true; //是否显示浮动下载app
  public ipdata: any; //首页ip信息
  public userId: any;
  @Watch("allDealCount")
  getAllDealCount(newVal: any) {
    if (Number(newVal) > 0) {
      this.isRunCountFloat = true;
      setTimeout(() => {
        this.isRunCountFloat = false;
      }, 6000);
    }
  }

  async created() {
    // this.userId = this.getCookie("userId");
    // let queryParams = this.getQueryParams(window.location.href)
    // if (queryParams) {
    //   if (queryParams.code != "") {
    //     this.handleWxChatLogin(this.userId, queryParams.code, "pc")
    //   }
    // }
  }

  async mounted() {
    this.userId = this.getCookie("userId");
    let queryParams = this.getQueryParams(window.location.href);
    console.log("queryParams", queryParams);
    if (
      queryParams.from === "kefuSystem" &&
      this.userId &&
      queryParams.visitor_id == this.userId
    ) {
      this.$confirm("客服向你分享一个聊天室?", "提示", {
        confirmButtonText: "查看",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.openToService(
            queryParams.customer_id,
            null,
            queryParams.is_room
          );
        })
        .catch(() => { });
    }

    if (queryParams.from === "kefuSystem" && !this.userId) {
      this.$router.push({
        path: "/login",
        query: queryParams,
      });
    }

    //进入页面刷新一次，拿到轮播图index
    // if (location.href.indexOf("#1") == -1) {
    //   location.href = location.href + "#1";
    //   location.reload();
    // }

    //百度推广
    // console.log('百度推广进入之前');

    // if (window.location.href.includes("?")) {
    //   console.log('百度推广进入之后');
    //   const addressUrl = window.location.href.split("?")[1]; //获取url中"?"符后的字串
    //   console.log(addressUrl,'++++');

    //   let obj = {};
    //   let arr = addressUrl.split("&");
    //   console.log(arr,'----');

    //   if (arr.length > 0) {
    //     for (let i = 0; i < arr.length; i++) {
    //       let arrNew = arr[i].split("=");
    //       obj[arrNew[0]] = arrNew[1];
    //     }
    //   } else {
    //     let arrNew = addressUrl.split("=");
    //     obj[arrNew[0]] = arrNew[1];
    //   }

    //   console.log(obj,'++++obj');

    //   if (obj["bd_vid"]) {
    //     console.log("拿到了bd_vid", obj["bd_vid"]);
    //     const params = JSON.stringify(obj["bd_vid"]);
    //     window.sessionStorage.setItem("bd_vid", params);
    //   }
    // }

    // this.carouselList = this.distributorsObject.adverPosMap["swiper"].filter(
    //   (v: any) => v.device == "0"
    // ); //轮播图里面遍历出来属性为device=0的标识符，获取到渐变色
    // let timer: any;
    // timer = setInterval(() => {
    //   let index = localStorage.getItem("_index");
    //   // console.log(index, "+++++++++++++++++++++++++++++++++++++++++++++++++++123456");
    //   if (index) {
    //     //判断index给背景色赋值
    //     // console.log("测试数据-------------------------------------------------------------",this.carouselList[index].back_colour)
    //     this.backclor = this.carouselList[Number(index) - 1].back_colour;
    //   }
    //   localStorage.removeItem("_index"); //不能删除，删除了定时器时间长了会把轮播图卡死
    // }, 500);

    console.log("jklnklmq", this.hotGamesArray);
    if (this.getCookie("token")) {
      this.isLogined = true;
      this.actor = this.getCookie("actor");
      this.nickName = this.getCookie("nickName");
    }
    console.log(this.distributorsObject, this.gameList, "sdgsagg");
    if (this.hotGamesArray.length == 0) {
      // vuex中热门游戏为空
      if (this.gameListArray.length > 0) {
        // 从vuex已存在游戏列表中拿hotgame
        let hotGameIdArray: any = [];
        let hotGameArray: any = [];
        if (this.$store.state.web.distributorsObject.distBaseConf.recGameStr) {
          console.log(
            "qdqdqwdwqdqwd",
            this.gameListArray,
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr
          );

          hotGameIdArray =
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(
              ","
            );
          for (let i: number = 0; i < hotGameIdArray.length; i++) {
            let gameFlag: any = this.gameListArray.find(
              (res: any) => res.id == hotGameIdArray[i]
            );
            if (gameFlag) {
              this.gameList.push(gameFlag);
              hotGameArray.push(gameFlag);
            }
          }
          this.SET_HOTGAMES_DATA(hotGameIdArray);
        }
      } else {
        // vuex中游戏列表不存在
        // console.log('qdqdqwdwqdqwd123123123');

        await this.getGameList(true);
      }
    }
    console.log("sdgsagg", this.gameList);
    let scrolltop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrolltop > 880) {
      if (!this.isFirstShowInClient) {
        this.isFirstShowInClient = true;
      }
      if (!this.isHaveRunSecondFloat) {
        setTimeout(() => {
          this.isHaveRunSecondFloat = true;
        }, 6000);
      }
    } else {
      window.addEventListener("scroll", () => {
        let scrolltop1 =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrolltop1 > 880) {
          if (!this.isFirstShowInClient) {
            this.isFirstShowInClient = true;
          }
          if (!this.isHaveRunSecondFloat) {
            setTimeout(() => {
              this.isHaveRunSecondFloat = true;
            }, 6000);
          }
        }
      });
    }
    // await this.getShowCount();   // 该方法集中处理 总成交 昨日成交 昨日浏览浏览
    this.getCode();
    await this.getNewsList();
    await this.getDouyinVideoList();
    await this.getCommonQuestions();
    await this.getRaffleData();
    // console.log('qwejlkjrklgnmfkm', this.allDealCount, this.yesterdayOrder, this.yesterdayView, "滚动新闻");
  }
  /**
   * 提取URL中的查询字符串部分
   * @param url
   */
  getQueryParams(url: string) {
    // 提取URL中的查询字符串部分
    const queryString = url.split("?")[1];

    if (!queryString) {
      return {};
    }
    const pairs = queryString.split("&");
    const params: any = {};
    pairs.forEach((pair) => {
      const [key, value] = pair.split("=");

      params[decodeURIComponent(key)] = decodeURIComponent(value) || "";
    });
    return params;
  }

  get filterHotGameList() {
    if (this.hotGamesArray.length > 0) {
      if (this.middleHotGameNowChooseItem == 1) {
        return this.hotGamesArray.filter(
          (v: any, i: any) => i > 0 && v.gameType == 1
        );
      } else if (this.middleHotGameNowChooseItem == 2) {
        return this.hotGamesArray.filter(
          (v: any, i: any) => i > 0 && v.gameType == 2
        );
      } else {
        return this.hotGamesArray.filter((v: any, i: any) => i > 0);
      }
    } else {
      return this.hotGamesArray;
    }
  }
  /**
   * 轮播图背景颜色事件
   * @param index 当前轮播图的index
   * @param list  轮播图数据
   */
  handleSlideChange(index: number, list: any[]) {
    let activeIndex;
    // console.log(index, list);
    if (index >= list.length + 1) {
      activeIndex = index - list.length;
    } else if (index <= 0) {
      activeIndex = index + list.length;
    } else {
      activeIndex = index;
    }
    // console.log(activeIndex, "activeIndex");
    this.backclor = list[activeIndex - 1].back_colour;
  }

  @Emit()
  async handleConfirmBind() {
    if (this.phoneNum == "") {
      this.$message.error("手机号不能为空");
      return;
    }

    if (this.verifyCode === "") {
      this.$message.error("验证码不能为空");
      return;
    }

    let inviteCode: string;
    inviteCode = "www.hk1686.com:" + this.inviteCode;
    let { data }: any = await loginBySmCodeHttp({
      method: "POST",
      data: {
        phoneNum: this.phoneNum,
        code: this.verifyCode,
        inviteCode: inviteCode,
      },
    });

    console.log(data.data, "当前的参数");

    if (data.data.user.loginStatus == 0) {
      this.setCookie("token", data.data.token);
      let userData: any = await getUserInfoHttp();
      if (this.errorToastFn(userData.data)) {
        localStorage.setItem("token", data.data.token);
        localStorage.setItem("userInfo", JSON.stringify(userData.data.data));
        this.setCookie("nickName", userData.data.data.nickName);
        this.setCookie("userId", userData.data.data.userId);
        this.setCookie("actor", userData.data.data.actor);
        this.setCookie("phoneNum", userData.data.data.phoneNum);
        // console.log(this.getCookie('phoneNum'),'1232222123123123')
        this.$message.success("登录成功!");
        let url = this.getCookie("beforePath");

        let currentTime: any = new Date();
        let specifiedTime: any = new Date(userData.data.data.createTime);
        let differenceInMilliseconds = currentTime - specifiedTime;
        let differenceInMinutes = differenceInMilliseconds / (1000 * 60);
        const params = window.sessionStorage.getItem("bd_vid");
        if (params && differenceInMinutes < 1) {
          let result = JSON.parse(params);
          // const baiduRequestUrl =
          //   'https://hk1686.com/' + `?bd_vid=${result}`;
          const baiduRequestUrl = window.location.href + `?bd_vid=${result}`;
          this.BAIDU(baiduRequestUrl);
          window.sessionStorage.removeItem("bd_vid");

          // if (url.includes("?")) {
          //   url = url + `&bd_vid=${result}`;
          // } else {
          //   url = url + `?bd_vid=${result}`;
          // }
        }
      }
    }

    //调用正常登录的接口
  }

  //发送验证码
  @Emit()
  sendCode() {
    if (this.countdown > 0) {
      return;
    }
    let vm = this;
    // let phoneNum = "";

    sendSmsCodeHttp({ method: "POST", data: { phoneNum: this.phoneNum } })
      .then((res: any) => {
        //发送手机验证码
        if (this.errorToastFn(res.data)) {
          // this.eventMsgTost("发送成功", "success");
          this.$message.success("发送成功");
          //开启倒计时
          vm.countdown = 60;
          settime();
          function settime() {
            if (vm.countdown == 0) {
              vm.sendCodeText = "获取验证码";
              return;
            } else {
              vm.sendCodeText = "重新发送(" + vm.countdown + ")";
              vm.countdown--;
            }
            setTimeout(function () {
              settime();
            }, 1000);
          }
        }
      })
      .catch((error) => {
        this.eventNotifyTost("提示", "网络超时", "warning");
      });
  }

  //当前微信登录状态
  @Emit()
  async handleWxChatLogin(userid: any, code: any, loginType: any) {
    let res = await weChatScanCodeloginApi({
      params: { userId: userid, code: code, loginType: loginType },
    });
    if (res?.data.code != 0) {
      this.$message.error(`${res?.data.msg}`);
    } else {
      if (
        res.data.data.user.loginStatus == 0 ||
        res.data.data.user.loginStatus == 1
      ) {
        this.setCookie("token", res.data.data.token);
        localStorage.setItem("token", res.data.data.token);
        localStorage.setItem("userInfo", JSON.stringify(res.data.data.user));
        this.setCookie("nickName", res.data.data.nickName);
        this.setCookie("userId", res.data.data.userId);
        this.setCookie("actor", res.data.data.actor);
        this.setCookie("phoneNum", res.data.data.phoneNum);
        this.$message.success("登录成功");
        console.log("获取token   更新个人信息接口  获取个人验证码");
      }

      if (res.data.data.user.loginStatus == 2) {
        //直接扫码登录的话 状态返回为2   就弹窗让他绑定发送验证码绑定手机号
        this.$message.error("没有注册账号，请先注册账号");
      }
    }
  }

  async getCode() {
    if (!this.getCookie("ip_code")) {
      let IP: any = await ipinfo();

      this.ipdata = IP?.data.data;

      if (this.$route.query.recommend_code) {
        this.inviteCode = this.$route.query.recommend_code;
        let data = await createInviteCodeRelate({
          method: "POST",
          data: {
            recommend_code: this.inviteCode,
          },
        });
        this.setCookie("ip_code", IP?.data.data + "|" + this.inviteCode);
        console.log(data, this.inviteCode, "1231qqqqq2312");
      }
    }
  }

  @Emit()
  toService() {
    // let h = document.getElementsByTagName('header')[0];
    // let s = document.createElement('script');
    // s.type = 'text/javascript';
    // s.src = "http://localhost:8081/static/js/kefu-front.js";
    // h.appendChild(s);
    // s.onload = s.onreadystatechange = function () {
    /*  s.onload  =  ()=>{
          KEFU.init({
          KEFU_URL:s.src,
          KEFU_KEFU_ID: "kefu2",
          KEFU_ENT: "2",
        })*/
    /*    if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")

      };
  }*/
  }
  async getRaffleData() {
    let userId = this.getCookie("userId");
    // console.log(userId,11511111);
    if (userId) {
      let res: any = await getRaffle({ params: { userId: userId } });
      if (res.data.code === 0 && res.data.data.Data !== null) {
        this.lotteryObj.list = res.data.data.Data;
        this.lotteryObj.count = res.data.data.Count;
        console.log(this.lotteryObj, 11511111);
        this.people = this.lotteryObj.count;
        if (this.lotteryObj.list[0].Code !== "") {
          this.code = this.lotteryObj.list[0].Code;
        }
        if (this.lotteryObj.list.length === 0) {
          this.isLottery = false;
        } else {
          this.isLottery = true;
        }
      } else {
        if (res.data.msg === "登录已过期,请重新登录") {
          this.isLottery = true;
        }
      }
    } else {
      let res: any = await getRaffle();
      if (res.data.code === 0 && res.data.data.Data !== null) {
        this.lotteryObj.list = res.data.data.Data;
        this.lotteryObj.count = res.data.data.Count;
        console.log(this.lotteryObj, 11511111);
        this.people = this.lotteryObj.count;
        if (this.lotteryObj.list[0].Code !== "") {
          this.code = this.lotteryObj.list[0].Code;
        }
        if (this.lotteryObj.list.length === 0) {
          this.isLottery = false;
        } else {
          this.isLottery = true;
        }
      } else {
        if (res.data.msg === "登录已过期,请重新登录") {
          this.isLottery = true;
        }
      }
    }
  }

  changeLottery() {
    this.isLottery = false;
  }

  @Emit()
  handleCloseDialog() {
    this.bindPhoneShow = false;
    this.phoneNum = "";
    this.verifyCode = "";
  }

  @Emit()
  checkLottery() {
    if (this.getCookie("token")) {
      this.openLottery = !this.openLottery;
      this.getRaffleData();
    } else {
      this.$router.push("/login");
    }
  }

  @Emit()
  // 获取筛选游戏列表
  async getGameList(flag: boolean = false) {
    console.log(
      this.$store.state.web.distributorsObject.distBaseConf.recGameStr,
      "=======recGameStr123========="
    );
    try {
      if (this.getCookie("token")) {
        await updateLastLogTime({
          method: "POST",
          data: { userId: this.getCookie("userId") },
        });
      }

      let { data } = await gameListHttp({});
      if (this.errorToastFn(data)) {
        console.log(data, "poqlwkjui");
        if (flag) {
          this.gameList = [];
          let hotGameIdArray: any = [];
          if (
            this.$store.state.web.distributorsObject.distBaseConf.recGameStr
          ) {
            let realHotGameArray: any = [];
            console.log(
              "qdqdqwdwqdqwd8899",
              this.$store.state.web.distributorsObject.distBaseConf.recGameStr
            );
            hotGameIdArray =
              this.$store.state.web.distributorsObject.distBaseConf.recGameStr.split(
                ","
              );
            for (let i: number = 0; i < hotGameIdArray.length; i++) {
              let gameFlag: any = data.data.find(
                (res: any) => res.id == hotGameIdArray[i]
              );
              if (gameFlag) {
                this.gameList.push(gameFlag);
                realHotGameArray.push(gameFlag);
              }
            }
            // if (!this.hotGamesArray || (this.hotGamesArray &&this.hotGamesArray.length < this.gameList.length)) {
            console.log("qdqdqwdwqdqwd5566998877", this.gameList);
            this.SET_HOTGAMES_DATA(realHotGameArray);
            // }
          }
          this.SET_GAMELIST_DATA(data.data);
        } else {
          this.gameList = data.data;
          this.SET_GAMELIST_DATA(this.gameList);
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
      this.gameList = [];
    }
  }

  // 中间热门游戏点击了端游手游的tabs
  hotGameTab(num: any) {
    if (num == this.middleHotGameNowChooseItem) {
      return;
    }
    this.middleHotGameNowChooseItem = num;
  }

  // 集中处理 总成交 昨日成交 昨日浏览量
  async getShowCount() {
    try {
      let allDealCount = 0;
      let yesterdayOrder = 0;
      let yesterdayView = 0;
      if (
        this.$store.state.web.distributorsObject &&
        this.$store.state.web.distributorsObject.distBaseConf.statisMap
      ) {
        let statisMap =
          this.$store.state.web.distributorsObject.distBaseConf.statisMap;
        if (!!statisMap && statisMap.includes("{")) {
          let flagMap = JSON.parse(statisMap);
          if (!!flagMap["dealAllCount"]) {
            allDealCount = flagMap["dealAllCount"];
          }
          if (!!flagMap["yesterdayOrder"]) {
            yesterdayOrder = flagMap["yesterdayOrder"];
          }
          if (!!flagMap["yesterdayView"]) {
            yesterdayView = flagMap["yesterdayView"];
          }
        }
      }
      // 请求分站总成交
      const { data } = await distOrderCountHttp();
      console.log("sdjfklsdjkfls", data);
      if (this.errorToastFn(data)) {
        allDealCount = allDealCount + data.data.count;
      }
      // 请求分站总成交
      const { data: data1 } = await dailyOrderHttp();
      console.log("sdjfklsdjkfls1", data1);
      if (this.errorToastFn(data1) && data1.data.goodsList.length > 1) {
        yesterdayOrder = yesterdayOrder + data1.data.goodsList[1].dealNum;
      }
      this.allDealCount = String(allDealCount);
      this.yesterdayOrder = yesterdayOrder;
      this.yesterdayView = yesterdayView;
    } catch (error) {
      console.log(error);
    }
  }

  //获取公告
  async getNewsList() {
    try {
      const { data }: any = await getNewsListHttp({
        params: { pages: 1, pageSize: 3 },
      });
      if (this.errorToastFn(data)) {
        if (data.data) {
          this.newsList = data.data.list;
        }
      }
    } catch (error) {
      this.eventNotifyTost("提示", "网络超时", "warning");
    }
    console.log(this.newsList, "新闻列表");
  }

  //找回视频
  async getDouyinVideoList() {
    const { data }: any = await dyVideoJumpManage({
      params: { pages: 1, pageSize: 3 },
    });
    // return
    console.log(data, "videoooooooo");
    if (this.errorToastFn(data)) {
      if (data.data) {
        this.douyinVideoList = data.data.data;
      }
    }
  }

  //常见问题
  async getCommonQuestions() {
    const { data }: any = await getNewsListHttp({
      params: { pages: 1, pageSize: 3, newsType: 21 },
    });
    console.log(data, "questionssssss");
    if (this.errorToastFn(data)) {
      if (data.data) {
        this.commonQuestionList = data.data.list;
      }
    }
  }

  goToDownloadApp() {
    // this.openUrl('/specilPage/downloadApp', '_blank')
    window.open("/specilPage/downloadApp", "_blank");
  }
}
